<template>
  <div class="container container-table" />
</template>
<script>
import ComponentHelper from '@/mixins/ComponentHelper'
export default {
  name: 'Logout',
  mixins: [ComponentHelper],
  created () {
    // this.$parent.logout()
  },
  mounted () {
    window.location.replace(`${this.config.serverInfo.auth}logout`)
  },
  methods: {}
}
</script>

<style>
</style>
